$dark: #222222;
$light: #f4f5f6;
$primary: #30acfe;

$font-family-base: "Open Sans", Helvetica, "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-weight-normal: 300;
$font-weight-light: 300;
$font-weight-lighter: $font-weight-light;
$font-weight-bold: 600;
$font-weight-bolder: $font-weight-bold;

$btn-font-weight: 400;

@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";

@import "../node_modules/aos/dist/aos.css";

@import "variables";
@import "../node_modules/bootstrap/scss/bootstrap";

html,
body {
  color: $dark;
}

.avatar {
  display: inline-block;
  vertical-align: middle;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: white url("/assets/images/icons/checkmark.png") no-repeat center center / 24px;
}

.container {
  padding: 0 30px;

  @include media-breakpoint-up(lg) {
    padding: 0 20px;
  }
}

.jumbotron {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 80vh;
  min-height: 400px;
  margin: 0;
  padding: 20px 40px;
  overflow: hidden;
  color: white;
  background-color: $dark;

  @include media-breakpoint-up(lg) {
    padding: 20px 80px;
  }

  &-background {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    height: 100%;
    width: 100%;
    background: url('/assets/images/unsplash/henry-be-DpI-_wydgJM-unsplash.jpg') no-repeat fixed center 0 / cover;
    opacity: .4;
  }

  &-content {
    position: relative;
    z-index: 2;
  }

  h1 {
    font-weight: 400;
  }

  p {
    max-width: 351px;
  }
}

.logo {
  display: block;
  max-width: 351px;

  img {
    display: block;
    width: 100%;
  }
}

.section {
  &-title {
    letter-spacing: 1.2px;
    text-align: center;
    text-transform: uppercase;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 14px;
  }

  &-description {
    max-width: 520px;
    font-size: 24px;
    line-height: 30px;
  }
}

.card-picture {
  display: block;
  height: auto;
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
  background: transparent no-repeat center center / contain;

  &::after {
    display: block;
    content: '';
    padding-top: 75%;
  }
}

.icon {
  display: inline-block;
  height: 24px;
  width: 24px;
  background: no-repeat center center / contain;

  &-envelope {
    background-image: url("/assets/images/icons/envelope.png");
  }

  &-facebook {
    background-image: url("/assets/images/icons/facebook.png");
  }

  &-linkedin {
    background-image: url("/assets/images/icons/linkedin.png");
  }
}

.btn-icon {
  display: inline-flex;
  align-items: center;

  .icon {
    margin-right: 8px;
    font-size: 1.4rem;
  }
}

.bg-plus {
  background-color: $light;
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ecedef' fill-opacity='1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
